import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled, { keyframes } from 'styled-components';

import { IoIosSearch, IoIosClose } from 'react-icons/io';

import {
  FaLinkedin,
  FaTwitterSquare,
  FaFacebook,
  FaAngleDown,
} from 'react-icons/fa';

import {
  screen,
  COLOR_DARK_GRAY,
  COLOR_WHITE,
  OVERLAY_GRAY,
} from '../common/variables';
import logoSymbol from './source-separation-systems-logo-symbol.svg';
import menuIcon from './source-separation-systems-menu-icon.svg';
import Search from './Search';
// animate open menu
const slideIn = keyframes`
from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const NavWrapper = styled.div`
  position: sticky;
  width: 100%;
  z-index: 99999;
  top: 0;

  @media ${screen.largeXL} {
    display: none;
  }

  .logo-menu-container {
    display: flex;
    justify-content: space-between;
    padding: 0 25px 0 5px;
    transition: all 0.4s ease-out;
    background: ${COLOR_WHITE};
    box-shadow: 0 1px 12px -5px ${COLOR_DARK_GRAY};

    a {
      outline: none;
    }

    &__logo {
      width: 185px;
      margin-top: -10px;
      margin-left: 10px;
    }

    &__logo-symbol {
      width: 46px;
      height: 46px;
      vertical-align: middle;
      padding: 13px 0 13px 15px;
    }

    &__brand-text {
      display: inline-block;
      margin-left: 10px;
      color: black;
      vertical-align: middle;
      font-size: 0.7rem;
      font-family: 'Helvetica Neue';

      /* font-family: 'Hero'; */
      @media (min-width: 451px) {
        font-size: 0.9rem;
      }
    }

    &__menu-icon {
      width: 26px;
      padding-top: 25px;
      align-self: flex-start;
      cursor: pointer;
    }
  }

  /* start opened menu */
  .menu-modal {
    background: ${COLOR_DARK_GRAY};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    overflow: auto;
    animation: ${slideIn} 0.25s;

    a {
      outline: none;
    }

    &__logo {
      width: 82px;
      background: ${COLOR_WHITE};
      padding: 26px;
      border-radius: 0 50% 50% 50%;
      margin-top: -16px;
      margin-left: -16px;
      @media ${screen.medium} {
        width: 115px;
        padding: 36px;
      }
    }

    &__close-btn {
      color: ${COLOR_WHITE};
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 3rem;
      cursor: pointer;
      @media ${screen.medium} {
        font-size: 4rem;
      }
    }

    &__search-btn {
      color: ${COLOR_WHITE};
      position: absolute;
      top: 1rem;
      right: 56px;
      height: 38px;
      font-size: 3rem;
      cursor: pointer;
      @media ${screen.medium} {
        font-size: 4rem;
        margin-top: 9px;
      }
    }

    &__link-list {
      margin-top: 50px;
      .active {
        color: ${COLOR_DARK_GRAY};
        background-color: ${COLOR_WHITE};
      }

      &--main-link {
        display: block;
        padding: 15px 35px;
        outline: none;
        font-size: 1.15rem;
        @media ${screen.medium} {
          font-size: 1.5rem;
        }
      }

      &--arrow-icon {
        vertical-align: middle;
        margin-left: 15px;
        display: inline-block;
        transition: all 0.2s ease-out;
      }
    }

    &__sublink-list {
      margin-top: 20px;
      a {
        display: block;
        font-weight: 100;
        color: ${OVERLAY_GRAY};
        outline: none;
        padding: 12px 35px;
        font-size: 1.05rem;
        @media ${screen.medium} {
          font-size: 1.4rem;
        }
      }
      a:first-child {
        margin-top: -10px;
      }
      a:last-child {
        margin-bottom: 14px;
      }
    }

    &__social-media {
      margin-top: 30px;
      padding: 0 35px 12px 35px;

      svg {
        margin-right: 20px;
        font-size: 1.25rem;
        border-radius: 7px;

        @media ${screen.medium} {
          font-size: 1.7rem;
        }
      }
    }

    &__search-container {
      background: ${COLOR_WHITE};
      padding: 30px 35px;
      margin-top: 35px;

      svg {
        vertical-align: middle;
      }

      input {
        background: none;
        border: 0;
        border-radius: 0;
        margin-left: 25px;
        outline: none;
        vertical-align: middle;
        &::placeholder {
          color: ${COLOR_DARK_GRAY};
          vertical-align: middle;
          @media ${screen.medium} {
          }
        }
      }
    }
  }
  /* end opened menu */
`;

class MobileNav extends Component {
  state = {
    modalNavIsOpen: false,
    subLinkIsOpen: false,
    subAboutLinkIsOpen: false,
    subServiceLinkIsOpen: false,
    query: '',
  };

  componentWillMount() {
    if (typeof window !== 'undefined') {
      this.bodyEl = document && document.querySelector('body');
    }
  }

  openSubLink = (e) => {
    e.preventDefault();
    this.setState({
      subLinkIsOpen: !this.state.subLinkIsOpen,
      subServiceLinkIsOpen: false,
      subAboutLinkIsOpen: false,
    });
  };

  openAboutSubLink = (e) => {
    e.preventDefault();
    this.setState({
      subAboutLinkIsOpen: !this.state.subAboutLinkIsOpen,
      subLinkIsOpen: false,
      subServiceLinkIsOpen: false,
    });
  };

  openServiceSubLink = (e) => {
    e.preventDefault();
    this.setState({
      subServiceLinkIsOpen: !this.state.subServiceLinkIsOpen,
      subLinkIsOpen: false,
      subAboutLinkIsOpen: false,
    });
  };

  onSearchChange = (e) => {
    this.setState({ query: e.target.value });
  };

  componentWillUnmount() {
    if (this.bodyEl) this.bodyEl.style.overflowY = 'auto';
  }

  render() {
    const {
      modalNavIsOpen,
      subLinkIsOpen,
      subServiceLinkIsOpen,
      subAboutLinkIsOpen,
    } = this.state;

    if (this.bodyEl) {
      this.bodyEl.style.overflowY = modalNavIsOpen ? 'hidden' : 'auto';
    }

    // const siteTitle = this.props.siteTitle;
    return (
      <NavWrapper>
        <div className="logo-menu-container">
          <div>
            <Link to="/">
              <img
                className="logo-menu-container__logo-symbol"
                src={logoSymbol}
                alt="logo"
              />
            </Link>
            <Link to="/" className="logo-menu-container__brand-text">
              SOURCE SEPARATION SYSTEMS
            </Link>
          </div>
          <img
            onClick={() => this.setState({ modalNavIsOpen: !modalNavIsOpen })}
            className="logo-menu-container__menu-icon"
            src={menuIcon}
            alt="menu-icon"
          />
        </div>
        {modalNavIsOpen ? (
          <div className="menu-modal">
            <Link
              to="/"
              onClick={() => this.setState({ modalNavIsOpen: !modalNavIsOpen })}
            >
              <img className="menu-modal__logo" src={logoSymbol} alt="logo" />
            </Link>
            <Link
              to="/search"
              onClick={() => this.setState({ modalNavIsOpen: !modalNavIsOpen })}
            >
              <IoIosSearch className="menu-modal__search-btn" />
            </Link>
            <IoIosClose
              onClick={() => this.setState({ modalNavIsOpen: !modalNavIsOpen })}
              className="menu-modal__close-btn"
            />
            <nav className="menu-modal__link-list">
              <Link
                className="menu-modal__link-list--main-link"
                to="/"
                activeClassName="active"
                onClick={() =>
                  this.setState({ modalNavIsOpen: !modalNavIsOpen })
                }
              >
                Home
              </Link>
              <Link
                className="menu-modal__link-list--main-link"
                to="/"
                onClick={this.openAboutSubLink}
              >
                About
                <FaAngleDown
                  style={{
                    transform: subAboutLinkIsOpen
                      ? `rotate(0deg)`
                      : `rotate(-90deg)`,
                  }}
                  className="menu-modal__link-list--arrow-icon"
                />
              </Link>
              {subAboutLinkIsOpen ? (
                <nav className="menu-modal__sublink-list">
                  <Link
                    to="/about"
                    activeClassName="active"
                    onClick={() =>
                      this.setState({ modalNavIsOpen: !modalNavIsOpen })
                    }
                  >
                    About Us
                  </Link>
                  <Link
                    to="/native-flora"
                    activeClassName="active"
                    onClick={() =>
                      this.setState({ modalNavIsOpen: !modalNavIsOpen })
                    }
                  >
                    Native Flora Program
                  </Link>
                </nav>
              ) : null}
              <Link
                onClick={this.openSubLink}
                className="menu-modal__link-list--main-link"
                to="/"
              >
                Recycling Products{' '}
                <FaAngleDown
                  style={{
                    transform: subLinkIsOpen
                      ? `rotate(0deg)`
                      : `rotate(-90deg)`,
                  }}
                  className="menu-modal__link-list--arrow-icon"
                />
              </Link>

              {subLinkIsOpen ? (
                <nav className="menu-modal__sublink-list">
                  <Link
                    to="/recycling-products/outdoors"
                    activeClassName="active"
                    onClick={() =>
                      this.setState({ modalNavIsOpen: !modalNavIsOpen })
                    }
                  >
                    Outdoors
                  </Link>
                  <Link
                    to="/recycling-products/home"
                    activeClassName="active"
                    onClick={() =>
                      this.setState({ modalNavIsOpen: !modalNavIsOpen })
                    }
                  >
                    Home
                  </Link>
                  <Link
                    to="/recycling-products/commercial"
                    activeClassName="active"
                    onClick={() =>
                      this.setState({ modalNavIsOpen: !modalNavIsOpen })
                    }
                  >
                    Commercial
                  </Link>
                  <Link
                    to="/recycling-products/hospital"
                    activeClassName="active"
                    onClick={() =>
                      this.setState({ modalNavIsOpen: !modalNavIsOpen })
                    }
                  >
                    Hospital
                  </Link>
                  <Link
                    to="/recycling-products/campus"
                    activeClassName="active"
                    onClick={() =>
                      this.setState({ modalNavIsOpen: !modalNavIsOpen })
                    }
                  >
                    Campus
                  </Link>
                  <Link
                    to="/recycling-products/school"
                    activeClassName="active"
                    onClick={() =>
                      this.setState({ modalNavIsOpen: !modalNavIsOpen })
                    }
                  >
                    School
                  </Link>
                </nav>
              ) : null}
              <Link
                className="menu-modal__link-list--main-link"
                to="/compost-a-pak/"
                activeClassName="active"
                onClick={() =>
                  this.setState({ modalNavIsOpen: !modalNavIsOpen })
                }
              >
                Compost-A-Pak
              </Link>
              <Link
                onClick={this.openServiceSubLink}
                className="menu-modal__link-list--main-link"
                to="/"
              >
                Services{' '}
                <FaAngleDown
                  style={{
                    transform: subServiceLinkIsOpen
                      ? `rotate(0deg)`
                      : `rotate(-90deg)`,
                  }}
                  className="menu-modal__link-list--arrow-icon"
                />
              </Link>
              {subServiceLinkIsOpen ? (
                <nav className="menu-modal__sublink-list">
                  <Link
                    to="/services"
                    activeClassName="active"
                    onClick={() =>
                      this.setState({ modalNavIsOpen: !modalNavIsOpen })
                    }
                  >
                    All Services
                  </Link>
                  <Link
                    to="/services/rollout-and-distribution-programs"
                    activeClassName="active"
                    onClick={() =>
                      this.setState({ modalNavIsOpen: !modalNavIsOpen })
                    }
                  >
                    Rollout & Distribution Programs
                  </Link>
                  <Link
                    to="/services/community-asset-auditing"
                    activeClassName="active"
                    onClick={() =>
                      this.setState({ modalNavIsOpen: !modalNavIsOpen })
                    }
                  >
                    Community Asset Auditing
                  </Link>
                  <Link
                    to="/services/custom-build-projects"
                    activeClassName="active"
                    onClick={() =>
                      this.setState({ modalNavIsOpen: !modalNavIsOpen })
                    }
                  >
                    Custom Build Projects
                  </Link>
                  <Link
                    to="/services/retrofit-recycling-solutions"
                    activeClassName="active"
                    onClick={() =>
                      this.setState({ modalNavIsOpen: !modalNavIsOpen })
                    }
                  >
                    Retrofit Recycling Solutions
                  </Link>

                  <Link
                    to="/services/single-use-plastic-free-freight"
                    activeClassName="active"
                    onClick={() =>
                      this.setState({ modalNavIsOpen: !modalNavIsOpen })
                    }
                  >
                    Single-Use Plastic Free Freight
                  </Link>
                </nav>
              ) : null}
              <Link
                className="menu-modal__link-list--main-link"
                to="/blog/"
                activeClassName="active"
                onClick={() =>
                  this.setState({ modalNavIsOpen: !modalNavIsOpen })
                }
              >
                Blog
              </Link>
              <Link
                className="menu-modal__link-list--main-link"
                to="/contact/"
                activeClassName="active"
                onClick={() =>
                  this.setState({ modalNavIsOpen: !modalNavIsOpen })
                }
              >
                Contact
              </Link>
              <div className="menu-modal__social-media">
                <a
                  href="https://www.facebook.com/sourceseparationsystems"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
                <a
                  href="https://twitter.com/SourceSepSystem"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitterSquare />
                </a>
                <a
                  href="https://www.linkedin.com/company/source-separation-systems/about/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </a>
              </div>
            </nav>

            <Search
              query={this.state.query}
              mobile
              searchable={this.props.searchable}
            />
          </div>
        ) : null}
      </NavWrapper>
    );
  }
}

export default MobileNav;
