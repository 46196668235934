import styled from 'styled-components';
import { Link } from 'gatsby';
// CSS Variables
export const COLOR_GRAY = '#3a3a3a';
export const COLOR_WHITE = '#ffffff';
export const COLOR_LIGHT_GRAY = '#e7e7e7';
export const COLOR_BORDER_GRAY = '#c9c9c9';
export const COLOR_BACKGROUND_GRAY = '#fafafa';
export const COLOR_DARK_GRAY = '#2c2c2c';
export const COLOR_TEXT_GRAY = '#808080';
export const COLOR_GREEN = '#69ad3f';
export const COLOR_DARK_GREEN = '#6cad3f';
export const COLOR_STREAM = '#666985';
export const BG_GRADIENT = 'linear-gradient(80deg, #69ad3f, #4c7d2e)';
export const OVERLAY_GRAY = 'rgba(231, 231, 231,.8)';
export const COLOR_RED = '#ff0000';
export const COLOR_PITCH = '#ffe4e4';
export const COLOR_DIRTY_WHITE = '#fafafa';
export const MAX_WIDTH = '1170px';
export const MAX_WIDTH_SITE = '1923px';
export const CONTAINED = `
 max-width: ${MAX_WIDTH};
 margin-left: auto;
 margin-right: auto;
`;

export const MAIN_FONT_STYLE = `
 font-family:'Source Sans Pro', Arial, Helvetica, sans-serif; 
`;

export const fontSz = (px) => {
  return `
   font-size: ${px}px;
   font-size: ${px / 16}rem;
  `;
};

export const MAIN_HEADING_FONT = `
  font-family: 'Hero', 'Source Sans Pro', Arial, Helvetica, sans-serif; 
`;

export const STREAM_coffee_c = 'coffee_c';
export const STREAM_comingle = 'comingle';
export const STREAM_dry_w = 'dry_w';
export const STREAM_ewaste = 'ewaste';
export const STREAM_landfill = 'landfill';
export const STREAM_organics = 'organics';
export const STREAM_paper_c = 'paper';
export const STREAM_recycling = 'recycling';
export const STREAM_soft_p = 'soft_p';
export const STREAM_refundables = '10c-refundables';
export const STREAM_glass = 'glass';

export const COLOR_NAME_MAP = {
  '#ffffff': 'White',
  '#1e73be': 'Blue',
  '#59d600': 'Lime Green',
  '#b2b2b2': 'Gray',
  '#dd3333': 'Red',
  '#eeee22': 'Yellow',
  '#81d742': 'Green',
  '#027a22': 'Dark Green',
  '#4c4c53': 'Grey',
  '#fb8700': 'Orange',
  '#bf7a4d': 'Brown',
  '#b3ef43': 'Green',
  '#c9baa5': 'Beige',
  '#000000': 'Black',
  '#670a12': 'Burgundy',
  '#777ec4': 'Light Purple',
  '#ac4fc6': 'Purple',
  '#4a4a4c': 'Woodland Grey',
};

export const HIDDEN_ITEMS = ['cG9zdDo2MDE=', 'cG9zdDo5NDg='];

export const STREAMS = [
  { id: 24, slug: 'comingle', name: 'Co-mingle', hidden: false },
  { id: 27, slug: 'coffee_c', name: 'Coffee Cups', hidden: false },
  { id: 29, slug: 'dry_w', name: 'Dry Waste', hidden: false },
  { id: 26, slug: 'ewaste', name: 'E-Waste', hidden: false },
  { id: 21, slug: 'landfill', name: 'Landfill', hidden: false },
  { id: 25, slug: 'organics', name: 'Organics', hidden: false },
  { id: 23, slug: 'paper', name: 'Paper', hidden: false },
  { id: 22, slug: 'recycling', name: 'Recycling', hidden: false },
  { id: 28, slug: 'soft_p', name: 'Soft Plastics', hidden: false },
  /* Hidden, only shown in product picker table */
  { id: 41, slug: 'your-choice', name: 'Your Choice', hidden: true },
  { id: 64, slug: '10c-refundables', name: '10C Refundables', hidden: false },
  { id: 42, slug: 'clinical-waste', name: 'Clinical Waste', hidden: true },
  { id: 40, slug: 'misc', name: 'Misc', hidden: true },
  { id: 40, slug: 'misc', name: 'Misc', hidden: true },
  { id: 1700, slug: 'glass', name: 'Glass', hidden: false },
];

export const screen = {
  small: `(min-width: 390px)`,
  medium: `(min-width: 576px)`,
  large: `(min-width: 768px)`,
  largeXL: `(min-width: 1080px)`,
  largeXXL: `(min-width: 1380px)`,
};

export const validStreams = [
  STREAM_coffee_c,
  STREAM_comingle,
  STREAM_dry_w,
  STREAM_ewaste,
  STREAM_landfill,
  STREAM_organics,
  STREAM_paper_c,
  STREAM_recycling,
  STREAM_soft_p,
  STREAM_refundables,
  STREAM_glass,
];

// export const emailRegX = /^([a-zA-Z\d\.-]+)@([a-zA-Z\d-]+)\.([a-zA-Z]{2,8})(\.[a-z]{2,8})?$/;
export const emailRegX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const phoneNumberRegX = /^[0-9()-]+$/;

// styled buttons/links. 'gray' and 'transparent' prop to change its colour
// styled button
export const Button = styled.button`
  background: ${(props) =>
    props.gray ? COLOR_GRAY : props.transparent ? `#ffffff` : COLOR_GREEN};
  color: ${(props) => (props.transparent ? COLOR_GREEN : COLOR_WHITE)};
  border: ${(props) =>
    props.gray ? `2px solid ${COLOR_GRAY}` : `2px solid ${COLOR_GREEN}`};
  display: table;
  padding: 0.5rem 1.85rem 0.3rem 1.85rem;
  font-size: 1rem;
  font-family: 'Hero', 'Source Sans Pro', Arial, Helvetica, sans-serif;
  border-radius: 5px;
  line-height: 1.15;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.28s ease-out;
  &:hover {
    background: ${(props) =>
      props.gray || (props.transparent && !props.graybg)
        ? COLOR_GREEN
        : COLOR_GRAY};
    color: ${COLOR_WHITE};
    border: ${(props) =>
      props.gray || props.transparent
        ? `2px solid ${COLOR_GREEN}`
        : `2px solid ${COLOR_GRAY}`};
  }

  @media ${screen.largeXL} {
    font-size: 1.1rem;
    padding: 0.6rem 2rem 0.4rem 2rem;
  }
`;

// styled gatsby Link
export const StyledLink = styled(Link)`
  background: ${(props) =>
    props.gray ? COLOR_GRAY : props.transparent ? `none` : COLOR_GREEN};
  color: ${(props) => (props.transparent ? COLOR_GREEN : COLOR_WHITE)};
  border: ${(props) =>
    props.gray ? `2px solid ${COLOR_GRAY}` : `2px solid ${COLOR_GREEN}`};
  display: table;
  padding: 0.5rem 1.85rem 0.3rem 1.85rem;
  font-size: 1rem;
  font-family: 'Hero', 'Source Sans Pro', Arial, Helvetica, sans-serif;
  border-radius: 5px;
  line-height: 1.15;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.28s ease-out;
  &:hover {
    background: ${(props) =>
      props.gray || props.transparent ? COLOR_GREEN : COLOR_GRAY};
    color: ${COLOR_WHITE};
    border: ${(props) =>
      props.gray || props.transparent
        ? `2px solid ${COLOR_GREEN}`
        : `2px solid ${COLOR_GRAY}`};
  }

  @media ${screen.largeXL} {
    font-size: 1.1rem;
    padding: 0.6rem 2rem 0.4rem 2rem;
  }
`;

export const StyledExternalLink = styled.a`
  background: ${(props) =>
    props.gray ? COLOR_GRAY : props.transparent ? `none` : COLOR_GREEN};
  color: ${(props) => (props.transparent ? COLOR_GREEN : COLOR_WHITE)};
  border: ${(props) =>
    props.gray ? `2px solid ${COLOR_GRAY}` : `2px solid ${COLOR_GREEN}`};
  display: table;
  padding: 0.5rem 1.85rem 0.3rem 1.85rem;
  font-size: 1rem;
  font-family: 'Hero', 'Source Sans Pro', Arial, Helvetica, sans-serif;
  border-radius: 5px;
  line-height: 1.15;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.28s ease-out;
  &:hover {
    background: ${(props) =>
      props.gray || props.transparent ? COLOR_GREEN : COLOR_GRAY};
    color: ${COLOR_WHITE};
    border: ${(props) =>
      props.gray || props.transparent
        ? `2px solid ${COLOR_GREEN}`
        : `2px solid ${COLOR_GRAY}`};
  }

  @media ${screen.largeXL} {
    font-size: 1.1rem;
    padding: 0.6rem 2rem 0.4rem 2rem;
  }
`;

// styled a tag
export const StyledA = styled.a`
  background: ${(props) =>
    props.gray ? COLOR_GRAY : props.transparent ? `none` : COLOR_GREEN};
  color: ${(props) => (props.transparent ? COLOR_GREEN : COLOR_WHITE)};
  border: ${(props) =>
    props.gray ? `2px solid ${COLOR_GRAY}` : `2px solid ${COLOR_GREEN}`};
  display: table;
  padding: 0.5rem 1.85rem 0.3rem 1.85rem;
  font-size: 1rem;
  font-family: 'Hero', 'Source Sans Pro', Arial, Helvetica, sans-serif;
  border-radius: 5px;
  line-height: 1.15;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.28s ease-out;
  &:hover {
    background: ${(props) =>
      props.gray || props.transparent ? COLOR_GREEN : COLOR_GRAY};
    color: ${COLOR_WHITE};
    border: ${(props) =>
      props.gray || props.transparent
        ? `2px solid ${COLOR_GREEN}`
        : `2px solid ${COLOR_GRAY}`};
  }

  @media ${screen.largeXL} {
    font-size: 1.1rem;
    padding: 0.6rem 2rem 0.4rem 2rem;
  }
`;
