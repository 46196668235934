import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import * as cssVar from './common/variables';
import Header from './Header';
import Footer from './Footer';
import './normalize.css';
import './base.css';
import {Popup} from "./popup";

const GlobalStyles = createGlobalStyle`
html, body{
  ${cssVar.MAIN_FONT_STYLE};
  ${cssVar.fontSz(18)}
}
:focus{
 outline: none;
}

section {
  margin-bottom: 2rem; 
}

a{
  text-decoration: none;
  color: ${cssVar.COLOR_WHITE};
}

ul {
  padding: 0;
}

h1, h2{
  ${cssVar.MAIN_HEADING_FONT}
  i{
    color: ${cssVar.COLOR_GREEN};
    font-style: normal;
  }
}

h1,h2, h3, h4, h5, h6, p{
  margin: 0;
}

p {
  font-weight: 300;
  line-height: 1.5rem;
}
.closeX:hover {
    cursor: pointer;
  }

.button{
  padding: .3rem 0.6rem;
  border-radius: 5px;
  border: 0;
  display: inline-block;
  line-height: 1.5;
  font-size: 1.1rem;
  color: ${cssVar.COLOR_WHITE};
  transition: all 0.28s ease-out;
  ${cssVar.MAIN_HEADING_FONT}
  ${cssVar.screen.medium} {
    font-size: 1.15rem;
    padding: .5rem 1rem;
  }
  &-fill{
    &-green{
      background-color: ${cssVar.COLOR_GREEN};
      &:hover {
      background-color: #3a3a3a;
    }
    }
    &-white{
      background-color: white;
      color: ${cssVar.COLOR_GREEN};
    }
  }
  &-outline{
    border: 1.5px solid ${cssVar.COLOR_GREEN};
    color: ${cssVar.COLOR_GREEN};
    &:hover {
      color: white!important;
      background: #69ad3f;
    }
  }
}

.chart-wrapper {
  display: none;
  @media ${cssVar.screen.medium} {
    display: unset;
  }
}

body > div.ReactModalPortal > div{
background: #fff;

}
.specSheetModal {
  z-index: 999;
  height: 100%;
  background: white;
  position: absolute;
  padding: 80px 56px 20px 33px;
  border-radius: 20px;                            
  top: 60%;
  left: 50%;
  right: auto;
  overflow: hidden;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 80px);
  h2 {
    width: 100%;
  }
  button {
    width: 106%;
    margin-right: -22px;
  }
  input {
    width: 100%;
  }
  &:focus {
    outline: none;
  }
  img {
    display: none;
  }
  .closeX {
      top: 74px!important;
      @media ${cssVar.screen.medium} {
        top: 40px!important;
      }
      @media ${cssVar.screen.largeXL} {
        top: 15px!important;
      }
    }
  @media ${cssVar.screen.largeXL} {
    h2 {
    width: 70%;
  }
   img {
      display: block;
   }
    height: 392px;
    max-width: 450px;
    top: 50%;
    padding: 30px 30px 20px 30px;
    button {
      width: auto;
      margin-right: 0;
    }
    input {
      width: auto;
    }
  }
}
.waste-area {
  @media (max-width: 1329px) {
    width: 100%!important;
  }
  margin-top: 3rem;
}
.mb-stream-select-option {
    border: 3px solid #6cad3f;
    margin: 9px;
    padding: 10px 23px;
    font-size: 14px;
    color: #6cad3f;
    border-radius: 11px;
    transition: background-color 0.2s, color 0.2s;
    &:hover {
      cursor: pointer;
    }
  }

  .mb-stream-select-option.active {
    color: white!important;
      background: #6CAD3F!important;

  }
`;

const Layout = ({ children }) => (
  <Fragment>
    <GlobalStyles />
    <Fragment>
      <Header siteTitle={`Source Separation System`} />
      <main>{children}</main>
      <Popup />
      <Footer />
    </Fragment>
  </Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
