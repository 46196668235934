import React, { Component, Fragment } from 'react';
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { IoIosSearch } from 'react-icons/io';

import { removeHTMLTags, generatePostURI } from '../common/functions';
import {
  Button,
  COLOR_TEXT_GRAY,
  COLOR_GRAY,
  screen,
} from '../common/variables';

import OutsideAlerter from '../../components/OutsideAlerter';

const SearchIconContainer = styled.a`
  display: none;
  position: relative;
  padding: 7px 0;
  color: white;
  &:hover {
    cursor: pointer;
    background: none !important;
  }
  margin: 2px 0px;
  svg {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 25px;
    @media ${screen.largeXL} {
      display: block;
    }
  }
  @media ${screen.largeXL} {
    display: block;
  }
`;

const ShowAll = styled.div`
  padding: 20px;
`;

const SearchContainer = styled.div`
  .result-container {
    position: absolute;
    background: white;
    z-index: 9999;
    right: 33px;
    top: 30px;
    width: 391px;
    box-shadow: 25px 0px 25px -25px rgba(0, 0, 0, 0.75),
      -25px 0px 25px -25px rgba(0, 0, 0, 0.75);

    .single-result-container {
      font-size: 14px;
      line-height: 1.2;
      margin: 7px;
    }

    .result-subcontainer {
      padding: 15px;
    }
  }

  .no-result-container {
    color: ${COLOR_TEXT_GRAY};
    padding: 20px;
    text-align: left;
    &__heading-text {
      font-weight: 400;
      font-size: 1.6rem;
      margin: 0rem 0rem 1rem 0rem;
    }
    p {
      font-size: 1rem;
      font-weight: 400;
      span {
        font-weight: 600;
      }
    }
  }
  .closeX {
    padding: 0px 7px;
    color: white;
    font-size: 40px;
    line-height: 41px;
    z-index: 99999;
    font-weight: 700;
    p {
      transform: rotateZ(45deg);
    }
  }
  .h3 {
    color: #69ad3f;
    padding: 5;
    font-weight: 300;
    width: fit-content;
    font-size: 1rem;
    margin-left: 7px;
  }
  .product-results {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-bottom: 1px solid #aaaaaa;

    .product-images {
      height: 75px;
      width: 75px;
    }
  }
  .darkbg {
    width: 100vw;
    height: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.23);
    z-index: 99;
  }
  .divider {
    border-bottom: 1px solid #aaaaaa;
    margin: 3px 5px;
    height: 1px;
    width: calc(100% - 25px);
  }
  .divider-2 {
    background: rgb(170, 170, 170);
    width: calc(100% - 5px);
    height: 1;
    margin: 0.6rem auto;
  }
  .articles-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #aaaaaa;

    .single-article-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-align: left;

      &__texts {
        width: 70%;
        .title {
          color: ${COLOR_GRAY};
          font-weight: 400;
        }
        .excerpt {
          color: ${COLOR_GRAY};
          padding: 5px 0px;
          font-weight: 300;
          line-height: 1.2;
        }
      }
      .featured-image {
        width: 80px !important;
        height: 80px !important;
      }
    }
  }
  .container {
    display: flex;
    margin-top: 5px;
    position: absolute;
    right: 0px;
    text-align: left;
    z-index: 9999999;

    .search-icon {
      position: absolute;
      right: 37px;
      top: 4px;
    }
    input {
      border-radius: 5px;
    }
  }
  .searchbar {
    height: 24px;
    border: none;
    text-indent: 5px;
    padding: 2px 5px 3px 10px;
    font-size: 17px;
    outline: none;
    width: 376px;
    line-height: 23px;
    color: ${COLOR_GRAY};
  }
  .product-title {
    color: ${COLOR_GRAY};
    max-width: 75px;
    padding: 5px 0px;
    text-align: left;
  }
`;

class Search extends Component {
  state = {
    query: '',
    isOpen: false,
  };

  recursiveIteration = (object) => {
    for (const property in object) {
      if (typeof object[property] !== 'string') continue;

      const value = object[property]
        .toString()
        .toUpperCase()
        .replace(/[^a-z0-9A-Z]/gim, '')
        .replace(/\s+/g, ' ');

      const query =
        this.props.query ||
        this.state.query
          .toUpperCase()
          .replace(/[^a-z0-9A-Z]/gim, '')
          .replace(/\s+/g, ' ');

      if (value.includes(query)) {
        return true;
      }
    }
  };

  sortBestMatch = (a, b) => {
    return this.getMatches(b) - this.getMatches(a);
  };

  getMatches = (object) => {
    let matches = 0;
    for (const property in object) {
      if (typeof object[property] !== 'string') continue;

      const value = object[property]
        .toString()
        .toUpperCase()
        .replace(/[^a-z0-9A-Z]/gim, '')
        .replace(/\s+/g, ' ');

      const query = this.state.query
        .toUpperCase()
        .replace(/[^a-z0-9A-Z]/gim, '')
        .replace(/\s+/g, ' ');

      if (value.includes(query)) {
        matches++;
      }
    }
    return matches;
  };

  onChange = ({ target }) => {
    this.setState({ query: target.value });
    if (this.props.setSearching) this.props.setSearching(true);
  };

  onClose = () => {
    this.setState({ query: '', isOpen: false });
    if (this.props.setSearching) this.props.setSearching(false);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isOpen && this.state.isOpen) {
      this.nameInput.focus();
    }
  }

  onOpen = () => {
    this.setState({ isOpen: true });
    if (this.props.setSearching) this.props.setSearching(true);
  };

  renderItem = (o) => {
    let content;
    if (['Product', 'CompostAPakProduct'].includes(o.nodeType)) {
      if (o.nodeType === 'CompostAPakProduct') {
        content = (
          <Link to={generatePostURI(o)}>
            <GatsbyImage
              className="product-images"
              image={
                o.acfCompostAPakProduct.mainImages.find(
                  (i) => i.image && i.image.localFile
                ).image.localFile.childImageSharp.gatsbyImageData
              }
              alt="c"
            />

            <div className="product-title">{o.title}</div>
          </Link>
        );
      } else {
        content = (
          <Link to={generatePostURI(o)}>
            <GatsbyImage
              className="product-images"
              image={
                o.acfProduct.mainImages.find(
                  (i) => i.image && i.image.localFile
                ).image.localFile.childImageSharp.gatsbyImageData
              }
              alt="c"
            />

            <div className="product-title">{o.title}</div>
          </Link>
        );
      }
    }

    if (o.nodeType === 'Post') {
      content = (
        <Link to={generatePostURI(o)} className="single-article-container">
          <div className="single-article-container__texts">
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: o.title }}
            />
            <div
              className="excerpt"
              dangerouslySetInnerHTML={{
                __html: removeHTMLTags(o.acfBlog.content).slice(0, 140) + '...',
              }}
            />
          </div>
          {o.acfBlog.featuredImage && (
            <GatsbyImage
              className="featured-image"
              image={
                o.acfBlog.featuredImage.localFile.childImageSharp
                  .gatsbyImageData
              }
              alt={o.acfBlog.featuredImage.title}
            />
          )}
        </Link>
      );
    }

    return (
      <div key={o.title} className="single-result-container">
        {content}
      </div>
    );
  };

  render() {
    const fullSearchable = this.props.searchable;
    // Filter out a product by wordpress post id coz of duplication of 1 specific product.
    const searchable = fullSearchable.filter(
      (searchable) => searchable.wordpress_id !== 2416
    );
    const results = searchable
      .filter(this.recursiveIteration)
      .sort(this.sortBestMatch);

    const productResults = results
      .filter((r) => ['Product', 'CompostAPakProduct'].includes(r.nodeType))
      .filter((o) => {
        if (o.nodeType === 'CompostAPakProduct') {
          if (
            !o.acfCompostAPakProduct.mainImages.find(
              (i) => i.image && i.image.localFile
            ).image ||
            !o.acfCompostAPakProduct.mainImages.find(
              (i) => i.image && i.image.localFile
            ).image.localFile
          ) {
            console.error(
              `[Alert] [Product] ${o.title} has no main_images.find(i => i.image && i.image.localFile)`
            );
            return false;
          }
          return true;
        } else {
          if (
            !o.acfProduct.mainImages.find((i) => i.image && i.image.localFile)
              .image ||
            !o.acfProduct.mainImages.find((i) => i.image && i.image.localFile)
              .image.localFile
          ) {
            console.error(
              `[Alert] [Product] ${o.title} has no main_images.find(i => i.image && i.image.localFile)`
            );
            return false;
          }
          return true;
        }
      })
      .slice(0, 4);

    const articleResults = results
      .filter((r) => r.nodeType === 'Post')
      .slice(0, 2);

    const isOpen = this.state.isOpen;

    if (!isOpen) {
      return (
        <SearchIconContainer onClick={this.onOpen}>
          <IoIosSearch />
        </SearchIconContainer>
      );
    }

    return (
      <SearchContainer>
        <div className="darkbg" />
        <OutsideAlerter onClick={this.onClose}>
          <div className="container">
            <IoIosSearch className="search-icon" />
            <input
              onKeyDown={(event) => {
                if (event.keyCode === 27) this.onClose();
                if (event.keyCode === 13) {
                  this.onClose();
                  navigate(`/search?query=${this.state.query || 'bin'}`);
                }
              }}
              ref={(input) => {
                this.nameInput = input;
              }}
              autoComplete="off"
              placeholder="Find products, articles and more..."
              value={this.state.query}
              onChange={this.onChange}
              type="text"
              name="name"
              className="searchbar"
            />
            {isOpen && (
              <Fragment>
                <div onClick={this.onClose} className="closeX">
                  <p>+</p>
                </div>
              </Fragment>
            )}
          </div>

          <div className="result-container">
            {this.state.query.length > 0 && <div className="divider" />}
            {results.length === 0 && (
              <div className="no-result-container">
                <h1 className="no-result-container__heading-text">
                  Sorry, no results found!
                </h1>
                <p>
                  We don't seem to be able to find anything that matches your
                  search for '<span>{this.state.query.trim()}</span>
                  '
                  <br />
                  <br />
                  If you want to chat with one of our team members, get in
                  touch!
                  <br />
                  <br />
                  <a href="tel:1300 739 913">
                    <Button className="call">Call {this.props.phone}</Button>
                  </a>
                </p>
              </div>
            )}
            {this.state.query.length > 0 && results.length > 0 && (
              <div className="result-subcontainer">
                <Fragment>
                  {productResults.length > 0 && (
                    <Fragment>
                      <h3 className="h3">Products</h3>
                      <div className="product-results">
                        {productResults.map((o) => this.renderItem(o))}
                      </div>
                    </Fragment>
                  )}
                  {articleResults.length > 0 && (
                    <Fragment>
                      {productResults.length > 0 && (
                        <div className="divider-2" />
                      )}
                      <h3 className="h3">Articles</h3>
                      <div className="articles-container">
                        {articleResults.map((o) => this.renderItem(o))}
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              </div>
            )}

            {this.state.query.length > 0 && results.length > 0 && (
              <ShowAll>
                <Link
                  to={`/search?query=${encodeURIComponent(this.state.query)}`}
                  onClick={this.onClose}
                >
                  <Button transparent>Show all Results</Button>
                </Link>
              </ShowAll>
            )}
          </div>
        </OutsideAlerter>
      </SearchContainer>
    );
  }
}

export default Search;
