import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import logo from './source-separation-systems-logo-symbol.svg';
import { MAX_WIDTH, COLOR_GRAY } from '../common/variables';
import Counter from './Counter';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${MAX_WIDTH};
  margin: 0 auto;
  align-items: flex-start;
  padding: 12px 0;

  .branding {
    align-self: center;
    display: flex;
  }

  .logo-container {
    img {
      /* width: 100px; */
      width: 120px;
    }
  }

  .logo-text {
    text-transform: uppercase;
    color: black;
    max-width: 157px;
    font-family: 'Helvetica Neue';
    font-weight: 400;
    font-size: 1.3rem;
    align-self: center;
    margin-left: 15px;
    color: ${COLOR_GRAY};
    line-height: 105%;
  }

  .counter-container {
    align-self: center;
  }
`;

const Branding = ({ siteTitle }) => (
  <Wrapper>
    <div className="branding">
      <div className="logo-container">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>

      <div className="logo-text">Source Separation Systems</div>
    </div>
    <div className="counter-container">
      <Counter />
    </div>
  </Wrapper>
);

export default Branding;
