import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ScrollLock from 'react-scrolllock';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import videoLandFill from '../../videos/landfill-video.mp4';

import {
  BG_GRADIENT,
  COLOR_WHITE,
  fontSz,
  COLOR_DARK_GREEN,
  COLOR_TEXT_GRAY,
  COLOR_GRAY,
} from '../common/variables';
import arrow from '../../images/header-icon-right.svg';

const CompactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 13px;
  .head-text,
  .digits {
    margin-right: 10px;
    cursor: pointer;
  }
  .digit {
    background: #69ad3f;
    border-radius: 3px;
    color: white;
    margin: 1px;
    padding: 0px 5px;
    font-size: 25px;
  }
  .digit-dot {
    font-size: 1.5rem;
    color: #69ad3f;
    padding: 0 1px;
  }
  .head-text {
    padding: 3px;
  }
`;

const Wrapper = styled.div`
  .flex-container {
    display: flex;
    justify-content: space-between;
    max-width: 620px;
    padding: 10px 8px 4px 22px;
    border-radius: 10px;
    background-image: ${BG_GRADIENT};
    color: ${COLOR_WHITE};
  }

  .digits-container {
    .digits {
      ${fontSz(32)}
    }
    .digit {
      background-color: white;
      margin: 0 2px;
      padding: 0 0.5rem;
      color: #69ad3f;
      border-radius: 5px;
    }
    .digit-0 {
      margin-left: 0;
    }
    .digit-dot {
      position: relative;
      top: 5px;
      margin: 0 3px;
    }

    .tonnes {
      position: relative;
      bottom: -8px;
      margin-left: 8px;
      font-size: 0.8rem;
      font-weight: 300;
      margin-bottom: 11px;
      display: inline-block;
    }
  }

  .link-container {
    width: 155px;
    position: relative;
    padding: 5px 10px;
    border-radius: 11px;
    margin: 0 0 0 37px;
    cursor: pointer;
    p {
      font-size: 0.8rem;
      line-height: normal;
      margin-top: -2px;
    }
    img {
      width: 20px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .head-text {
    color: ${COLOR_DARK_GREEN};
    margin-top: 0;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 1.2rem;
    margin-left: 24px;
  }
`;

export const VideoModalContainer = styled.div`
  background: rgba(58, 58, 58, 0.95);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;

  .subcontainer {
    position: relative;
    margin: auto;
    display: block;

    video {
      border: ${COLOR_GRAY} 10px solid;
      outline: none;
    }

    svg {
      color: ${COLOR_WHITE};
      font-size: 2.5rem;
      position: absolute;
      z-index: 12;
      top: -25px;
      right: -25px;
      cursor: pointer;
      transition: all 0.28s ease-out;
      z-index: 1111;
      &:hover {
        color: ${COLOR_TEXT_GRAY};
      }
    }
  }
`;
class Counter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { timeCount: 0 };
    this.renderDigits = this.renderDigits.bind(this);
    this.videoIsShowing = true;
  }
  componentDidMount() {
    const allCookies = document.cookie;
    if (allCookies.indexOf('initTime=') === -1) {
      const initTime = Date.now();
      document.cookie = `initTime=${initTime}`;
      document.cookie = 'anotherTestCookie=29fgmzxcvfefzxcv';
      this.updateTime(initTime);
    } else {
      const startTime = this.getCookieValue('initTime');
      const timePassed = Date.now() - startTime;
      const timeCount = Math.round(timePassed / 10);
      this.setState({ timeCount }, () => {
        this.updateTime(startTime);
      });
    }
  }
  componentWillUnmount() {
    clearInterval(this.timerId);
  }
  updateTime = initTime => {
    this.timerId = setInterval(() => {
      this.setState({
        timeCount: Math.round((Date.now() - initTime) / 10),
      });
    }, 10);
  };

  getCookieValue = cookieName => {
    const allCookies = document.cookie;
    let cookies = allCookies.split(';');
    for (let i = 0; i < cookies.length; i++) {
      if (cookies[i][0] === ' ') {
        cookies[i] = cookies[i].substring(1);
      }
      if (cookies[i].substring(0, cookieName.length) === cookieName) {
        return cookies[i].substring(cookieName.length + 1);
      }
    }
  };

  renderDigits() {
    let str = this.state.timeCount.toString();
    let result = [];
    str = (Array(7).join('0') + str).slice(-7);
    for (let i = 0; i < str.length; i++) {
      if (i === str.length - 2) {
        result.push(
          <span key="dot" className="digit-dot">
            .
          </span>
        );
      }
      result.push(
        <span key={`digit#${i}`} className={`digit digit-${i}`}>
          {str.charAt(i)}
        </span>
      );
    }
    return result;
  }
  render() {
    const { compact } = this.props;
    const { videoIsShowing } = this.state;
    if (compact) {
      return (
        <CompactWrapper>
          <p className="head-text">
            Australian landfill deposited during your visit
          </p>
          <div className="flex-container">
            <div className="digits-container">
              <span className="digits">{this.renderDigits(true)}</span>
              <span className="tonnes">Tonnes</span>
            </div>
          </div>
        </CompactWrapper>
      );
    }
    return (
      <React.Fragment>
        <Wrapper>
          <p className="head-text">
            Australian landfill deposited during your visit
          </p>
          <div className="flex-container">
            <div className="digits-container">
              <span className="digits">{this.renderDigits()}</span>
              <span className="tonnes">Tonnes</span>
            </div>
            <div className="link-container">
              <p
                onClick={() => {
                  this.setState({ videoIsShowing: !videoIsShowing });
                }}
              >
                Learn why we are so
                <br />
                passionate about this
              </p>
              <img
                onClick={() => {
                  this.setState({ videoIsShowing: !videoIsShowing });
                }}
                src={arrow}
                alt="arrow"
              />
            </div>
          </div>
        </Wrapper>
        {videoIsShowing ? (
          <VideoModalContainer>
            <div className="subcontainer">
              <IoIosCloseCircleOutline
                onClick={() => {
                  this.setState({ videoIsShowing: !videoIsShowing });
                }}
              />
              <video controls autoPlay>
                <source src={videoLandFill} type="video/mp4" />
              </video>
            </div>
            <ScrollLock />
          </VideoModalContainer>
        ) : null}
      </React.Fragment>
    );
  }
}

export default Counter;
