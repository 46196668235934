import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FaAngleRight } from 'react-icons/fa';
import Search from './Search';
import {
  COLOR_GRAY,
  COLOR_WHITE,
  MAX_WIDTH_SITE,
  MAX_WIDTH,
} from '../common/variables';
import IconCampus from './assets/source-separation-systems-campus.svg';
import IconCommercial from './assets/source-separation-systems-commercial.svg';
import IconHome from './assets/source-separation-systems-home.svg';
import IconHospital from './assets/source-separation-systems-hospital.svg';
import IconOutdoors from './assets/source-separation-systems-outdoors.svg';
import IconSchool from './assets/source-separation-systems-school.svg';

const Navbar = styled.nav`
  background: ${COLOR_GRAY};
  max-width: ${MAX_WIDTH_SITE};
  width: 100%;
  margin: 0 auto;
  #top-menu {
    max-width: ${MAX_WIDTH};
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    list-style-type: none;
    padding: 0;
    min-height: 38px;
    max-height: 38px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      max-width: 1400px;
    }
    li {
      line-height: normal;
      position: relative;
      width: 100%;
      text-align: center;
    }
    .main-links {
      font-size: 1rem;
      outline: none;
      font-weight: 300;
      display: block;
      padding: 8px 0;
      &:hover {
        background: ${COLOR_WHITE};
        color: ${COLOR_GRAY};
        svg path,
        svg circle {
          fill: ${COLOR_GRAY};
        }
      }
    }
    .sublink {
      position: relative;
      a {
        display: block;
        padding: 8px 35px;
        white-space: nowrap;
      }
      svg {
        vertical-align: middle;
        transition: all 0.2s ease-out;
        margin-left: 5px;
        margin-bottom: 3px;
      }
    }
  }
  .active {
    color: ${COLOR_GRAY};
    background-color: ${COLOR_WHITE};
    svg path,
    svg circle {
      fill: ${COLOR_GRAY};
    }
  }
`;
const SubMenuProductsContainer = styled.div`
  position: absolute;
  background: ${COLOR_GRAY};
  top: 38px;
  z-index: 9;
  width: 100%;
  box-shadow: 25px 0px 25px -25px rgba(0, 0, 0, 0.75),
    -25px 0px 25px -25px rgba(0, 0, 0, 0.75);
  a {
    display: inline-block;
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    padding: 8px 0 8px 8px !important;
    outline: none;
    &:hover {
      color: ${COLOR_GRAY};
      background-color: ${COLOR_WHITE};
      svg path,
      svg circle {
        fill: ${COLOR_GRAY};
      }
    }
  }
  svg {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    vertical-align: middle;
  }
  hr {
    border-top: solid 1px rgba(255, 255, 255, 0.2);
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    margin: 0;
    width: 88%;
    margin: 0 auto;
  }
`;

const SubMenuAboutContainer = styled.div`
  position: absolute;
  background: ${COLOR_GRAY};
  top: 38px;
  z-index: 9;
  width: 212px !important;
  margin-top: -1px;
  box-shadow: 25px 0px 25px -25px rgba(0, 0, 0, 0.75),
    -25px 0px 25px -25px rgba(0, 0, 0, 0.75);
  a {
    display: block;
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    padding: 8px 134px 8px 34px !important;
    outline: none;
    &:hover {
      color: ${COLOR_GRAY};
      background-color: ${COLOR_WHITE};
      svg path,
      svg circle {
        fill: ${COLOR_GRAY};
      }
    }
  }
  hr {
    border-top: solid 1px rgba(255, 255, 255, 0.2);
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    width: 88%;
    margin: 0 auto;
  }
`;
const SubMenuServicesContainer = styled.div`
  position: absolute;
  background: ${COLOR_GRAY};
  top: 38px;
  z-index: 9;
  width: 292px;
  margin-top: -1px;
  box-shadow: 25px 0px 25px -25px rgba(0, 0, 0, 0.75),
    -25px 0px 25px -25px rgba(0, 0, 0, 0.75);
  a {
    display: block;
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    padding: 8px 134px 8px 34px !important;
    outline: none;
    &:hover {
      color: ${COLOR_GRAY};
      background-color: ${COLOR_WHITE};
      svg path,
      svg circle {
        fill: ${COLOR_GRAY};
      }
    }
  }
  hr {
    border-top: solid 1px rgba(255, 255, 255, 0.2);
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    width: 88%;
    margin: 0 auto;
  }
`;

class Menus extends React.Component {
  state = {
    showSubMenu: false,
    showAboutMenu: false,
    showServicesMenu: false,
    isSearching: false,
    width: 0,
    height: 0,
  };
  setSearching = (bool) => {
    if (bool === this.state.isSearching) return;
    this.setState({ isSearching: bool });
  };
  renderSubMenus = () => (
    <div>
      <hr />
      <Link to="/recycling-products/outdoors" activeClassName="active">
        <IconOutdoors />
        Outdoors
      </Link>
      <hr />
      <Link to="/recycling-products/home" activeClassName="active">
        <IconHome />
        Home
      </Link>
      <hr />
      <Link to="/recycling-products/commercial" activeClassName="active">
        <IconCommercial />
        Commercial
      </Link>
      <hr />
      <Link to="/recycling-products/hospital" activeClassName="active">
        <IconHospital />
        Hospital
      </Link>
      <hr />
      <Link to="/recycling-products/campus" activeClassName="active">
        <IconCampus />
        Campus
      </Link>
      <hr />
      <Link to="/recycling-products/school" activeClassName="active">
        <IconSchool />
        School
      </Link>
    </div>
  );
  renderServicesSubMenus = () => (
    <div>
      <hr />
      <Link to="/services" activeClassName="active">
        All Services
      </Link>
      <hr />
      <Link
        to="/services/rollout-and-distribution-programs"
        activeClassName="active"
      >
        Rollout & Distribution Programs
      </Link>
      <hr />
      <Link to="/services/community-asset-auditing" activeClassName="active">
        Community Asset Auditing
      </Link>
      <hr />
      <Link to="/services/custom-build-projects" activeClassName="active">
        Custom Build Projects
      </Link>
      <hr />
      <Link
        to="/services/retrofit-recycling-solutions"
        activeClassName="active"
      >
        Retrofit Recycling Solutions
      </Link>
      <hr />
      <Link
        to="/services/single-use-plastic-free-freight"
        activeClassName="active"
      >
        Single-Use Plastic Free Freight
      </Link>
    </div>
  );

  renderAboutSubMenus = () => (
    <div>
      <hr />
      <Link to="/about" activeClassName="active">
        About Us
      </Link>
      <hr />
      <Link to="/native-flora" activeClassName="active">
        Native Flora Program
      </Link>
    </div>
  );

  detectClickedTarget = (e) => {
    if (e.target.className !== 'main-links' || e.keyCode === 27) {
      this.setState({
        showSubMenu: false,
        showServicesMenu: false,
        showAboutMenu: false,
      });
    }
  };
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('click', this.detectClickedTarget);
    window.addEventListener('keyup', this.detectClickedTarget);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('click', this.detectClickedTarget);
    window.removeEventListener('keyup', this.detectClickedTarget);
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      isSearching: window.innerWidth < 1080,
    });
  };
  render() {
    return (
      <Navbar>
        <ul id="top-menu">
          {!this.state.isSearching && (
            <Fragment>
              <li>
                <Link className="main-links" to="/">
                  Home
                </Link>
              </li>
              <li className="sublink">
                <Link
                  className="main-links"
                  to="/"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      showAboutMenu: !this.state.showAboutMenu,
                      showSubMenu: false,
                      showServicesMenu: false,
                    });
                  }}
                >
                  About
                  {this.state.showAboutMenu ? (
                    <FaAngleRight style={{ transform: 'rotate(90deg)' }} />
                  ) : (
                    <FaAngleRight />
                  )}
                </Link>
                <SubMenuAboutContainer>
                  {this.state.showAboutMenu ? this.renderAboutSubMenus() : null}
                </SubMenuAboutContainer>
              </li>
              <li className="sublink">
                <Link
                  className="main-links"
                  to="/"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      showSubMenu: !this.state.showSubMenu,
                      showAboutMenu: false,
                      showServicesMenu: false,
                    });
                  }}
                >
                  Recycling Products{' '}
                  {this.state.showSubMenu ? (
                    <FaAngleRight style={{ transform: 'rotate(90deg)' }} />
                  ) : (
                    <FaAngleRight />
                  )}
                </Link>
                <SubMenuProductsContainer>
                  {this.state.showSubMenu ? this.renderSubMenus() : null}
                </SubMenuProductsContainer>
              </li>
              <li className="sublink">
                <Link
                  className="main-links"
                  to="/compost-a-pak/"
                  activeClassName="active"
                >
                  Compost-A-Pak<span>&reg;</span>
                </Link>
              </li>
              <li className="sublink">
                <Link
                  className="main-links"
                  to="/"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      showServicesMenu: !this.state.showServicesMenu,
                      showAboutMenu: false,
                      showSubMenu: false,
                    });
                  }}
                >
                  Services{' '}
                  {this.state.showServicesMenu ? (
                    <FaAngleRight style={{ transform: 'rotate(90deg)' }} />
                  ) : (
                    <FaAngleRight />
                  )}
                </Link>
                <SubMenuServicesContainer>
                  {this.state.showServicesMenu
                    ? this.renderServicesSubMenus()
                    : null}
                </SubMenuServicesContainer>
              </li>
              <li>
                <Link
                  className="main-links"
                  to="/blog/"
                  activeClassName="active"
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  className="main-links"
                  to="/contact/"
                  activeClassName="active"
                >
                  Contact
                </Link>
              </li>
            </Fragment>
          )}
          <li onClick={() => this.setSearching(true)}>
            {this.state.width >= 1080 && (
              <Search
                phone={this.props.phone}
                setSearching={this.setSearching}
                searchable={this.props.searchable}
              />
            )}
          </li>
        </ul>
      </Navbar>
    );
  }
}

export default Menus;
