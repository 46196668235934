import sanitizeHtml from 'sanitize-html-react';

export function chunk(entries, chunkSize) {
  const clone = entries.slice();
  const chunks = [];
  while (clone.length) chunks.push(clone.splice(0, chunkSize));
  return chunks;
}

export const flatten = (list) =>
  list.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);

export function removeHTMLTags(str) {
  if (str) {
    return sanitizeHtml(str, { allowedTags: [], allowedAttributes: {} });
  }
}

export function removeNBSP(str) {
  if (str) {
    return str.replace(/\u00A0/g, '');
  }
}

export function generatePostURI(obj) {
  if (obj.node) {
    switch (obj.node.nodeType) {
      case 'Product':
        return `/product/${obj.node.slug}`;
      case 'Post':
        return `/blog/${obj.node.slug}`;
      case 'CompostAPakProduct':
        return `/compost-a-pak/${obj.node.slug}`;
      default:
        console.error('[Alert] Invalid URI case.');
        return '/';
    }
  } else {
    switch (obj.nodeType) {
      case 'Product':
        return `/product/${obj.slug}`;
      case 'Post':
        return `/blog/${obj.slug}`;
      case 'CompostAPakProduct':
        return `/compost-a-pak/${obj.slug}`;
      default:
        console.error('[Alert] Invalid URI case.');
        return '/';
    }
  }
}

export function loadState(key) {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (_) {
    return undefined;
  }
}

export function saveState(key, value) {
  try {
    return localStorage.setItem(key, JSON.stringify(value));
  } catch (_) {
    return undefined;
  }
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function prodIsValid(acfProduct) {
  for (let i = 0; i < 5; i++) {
    if (
      !acfProduct.mainImages[i] ||
      (!acfProduct.mainImages[i].image && !acfProduct.mainImages[i].video)
    )
      return false;
  }
  return true;
}

export function getWidth(document) {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

export function getHeight(document) {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );
}

export function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export function stripLastSlash(url) {
  const stripped = url.endsWith('/') ? url.slice(0, -1) : url;
  return stripped.split('/').pop();
}
