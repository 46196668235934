import React, {useState, useEffect} from "react";
import styled from 'styled-components';

const PopupWrapper = styled.div`
  position: fixed !important;
  bottom: 15px;
  right: 20px;
  z-index: 9999;
  font-family: 'Hero','Source Sans Pro',Arial,Helvetica,sans-serif;
`;

const PopupBox = styled.div`
  position: relative !important;
  transition: all 0.5s ease-out;
`;

const PopupInFo = styled.div`
  position: absolute !important;
  transition: all 0.28s ease-out;
  right: 0;
  width: calc(100vw - 24px);
  max-width: 350px;
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.15) !important;
  border-radius: 12px;
  background: #fff;
  border: 1px solid #040404;
  overflow: hidden;
  bottom: 80px;
`;

const PopupHeader = styled.div`
  background: #69ad3f;
  padding: 20px;
  text-align: center;
  color: #fff;
  h2 {
    font-family: 'Hero','Source Sans Pro',Arial,Helvetica,sans-serif;
    display: inline-block !important;
    font-size: 1.5em;
    font-weight: bold !important;
  }
`;

const PopupContent = styled.div`
  padding: 32px 20px;
  text-align: center;
  color: #343434;
  font-family: 'Hero','Source Sans Pro',Arial,Helvetica,sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  .phone {
    color: #69ad3f;
    font-size: 1.1em;
    font-weight: 400 !important;
    margin: 4px 0;
    display: block;
  }
  h4 {
    display: inline-block !important;
    font-size: 1.25em;
    font-weight: 400 !important;
    margin-bottom: 8px;
  }
  .email {
    color: #121212;
    font-size: .75em;
    font-family: 'Source Sans Pro',Arial,Helvetica,sans-serif;
  }
  p {
    font-size: .75em;
    line-height: 1.25em;
    margin: 0;
    padding: 0;
    color: #929292;
    font-weight: 400;
    white-space: pre-wrap;
  }
  .greenText {
    color: #69ad3f;
    font-weight: 400 !important;
    margin: 0;
  }
`;

const ButtonCustom = styled.div`
  background: #69ad3f;
  color: #ffffff;
  border: 2px solid #69ad3f;
  padding: 0.8rem 1rem 0.7rem 1rem;
  font-size: 1rem;
  font-family: 'Hero','Source Sans Pro',Arial,Helvetica,sans-serif;
  border-radius: 5px;
  line-height: 1.15;
  font-weight: 400;
  cursor: pointer;
  -webkit-transition: all 0.28s ease-out;
  transition: all 0.5s ease-out;
  height: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
  &hover {
    background: #3a3a3a;
    color: #ffffff;
    border: 2px solid #3a3a3a;
  }
`;

export const Popup = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isShow, setIsShow] = useState(false)
  useEffect(() => {
    if (!window || !localStorage) return
    setIsOpen(localStorage.getItem("openPopup") === "true")
    setTimeout(()=> {
      setIsShow(true)
      if (!localStorage.getItem("newPopup")) {
        localStorage.setItem("newPopup", String(true));
      }
    }, 50)
    setTimeout(()=> {
      if (localStorage.getItem("newPopup") === "false") return
      setIsOpen(true)
      localStorage.setItem("openPopup", String(true));
      localStorage.setItem("newPopup", String(false));
    }, 5000)
  },[])

  const handleClick = () => {
    if (!window || !localStorage) return
    localStorage.setItem("openPopup", String(!isOpen));
    setIsOpen(!isOpen)
  }
  return (
    <PopupWrapper>
      <PopupBox style={{ opacity: isShow ? 1 : 0, transform: `scale(${isShow ? 1 : 0.1})` }}>
        <ButtonCustom onClick={handleClick}>
          {
            isOpen ? (
              <svg className="olark-close-icon" width="20" height="20" viewBox="0 0 20 20" role="img" aria-hidden="true">
                <path d="M18 2L2 18" stroke="currentcolor" strokeWidth="4" strokeLinecap="round"
                      strokeLinejoin="round"></path>
                <path d="M2 2L18 18" stroke="currentcolor" strokeWidth="4" strokeLinecap="round"
                      strokeLinejoin="round"></path>
              </svg>
            ) : "Need assistance?"
          }
        </ButtonCustom>
        <PopupInFo style={{ opacity: isOpen ? 1 : 0, transform: `scale(${isOpen ? 1 : 0})` }}>
          <PopupHeader>
            <h2>Need assistance?</h2>
          </PopupHeader>
          <PopupContent>
            <div>
              <h5>Simply call</h5>
              <a className={"phone"} href="tel:0249537644">02 49 537 644</a>
              <p>to talk to our Waste Diversion Experts</p>
            </div>
            <div><p className={'greenText'}>{`No Chat Robot... No AI... No IVR...\nJust a friendly Aussie Human Being.`}</p></div>
            <div>
              <p>If it’s afterhours, please email and we will get back to you as soon as we return. </p>
              <a className={"email"} href="mailto:info@sourceseparationsystems.com.au">info@sourceseparationsystems.com.au</a>
            </div>
          </PopupContent>
        </PopupInFo>
      </PopupBox>
    </PopupWrapper>
  )
}