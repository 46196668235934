import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import floraBG from '../../images/source-separation-systems-native-flora-program.jpg';
import member1 from '../../images/source-separation-systems-local-government-procurement.png';
import member2 from '../../images/source-separation-systems-local-government-wmaa.png';
import facebook from '../../images/facebookLogo.png';
import linkedin from '../../images/linkedinLogo.png';
import twitter from '../../images/twitterLogo.png';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import loaderIcon from '../../images/three-dots.svg';

import {
  COLOR_GRAY,
  COLOR_DARK_GRAY,
  COLOR_DARK_GREEN,
  screen,
  MAX_WIDTH_SITE,
  MAX_WIDTH,
  StyledLink,
  emailRegX,
  COLOR_LIGHT_GRAY,
  Button,
} from '../common/variables';

const Wrapper = styled.footer`
  background-color: ${COLOR_GRAY};
  max-width: ${MAX_WIDTH_SITE};
  margin: 0 auto;
  .footer-container {
    display: flex;
  }

  .footer-left {
    width: 21%;
    /* margin-left: calc(50% - 585px); */
    padding: 28px 0 26px 35px;
    display: none;
    @media ${screen.largeXL} {
      display: block;
    }
    @media ${screen.largeXXL} {
      opacity: 1;
      margin-left: calc(50% - 585px);
      padding: 28px 0 26px 0;
    }

    a {
      display: block;
      font-size: 0.95rem;
      margin-bottom: 10px;
      &:hover {
        color: ${COLOR_DARK_GREEN};
      }
    }

    h5 {
      color: #fff;
      margin: 0;
      font-size: 1.1rem;
    }

    ul {
      margin: 20px 0 0 0;

      li {
        list-style: none;
      }

      .recycling-products-link-list {
        h6 {
          color: #fff;
          margin-bottom: 5px;
          font-size: 0.95rem;
        }
        a {
          display: block;
          font-size: 0.875rem;
          margin-left: 7px;
          margin-bottom: 5px;
          font-weight: 300;
        }
      }
    }
  }

  .footer-right {
    background-image: url(${floraBG});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    position: relative;
    padding: 22px 0;
    @media ${screen.medium} {
      background-size: 100%;
    }
    @media ${screen.largeXL} {
      width: 79%;
    }

    &__social-media {
      margin-left: 40px;
      margin-top: 8px;
      display: none;
      @media ${screen.large} {
        display: block;
      }

      .heading-text {
        color: #fff;
        font-size: 1.1rem;
        margin-bottom: 15px;
      }

      a {
        outline: none;
        img {
          width: 30px;
          display: inline-block;
          margin-right: 10px;
        }
      }

      .member-logos {
        margin-top: 30px;
        img {
          width: 150px;
          display: block;
          margin-bottom: 15px;
        }
        &__government {
          margin-left: -9px;
        }
        &__wmaa {
          margin-left: -2px;
        }
      }
    }

    &__newsletter {
      padding: 0 7px;
      @media ${screen.medium} {
        padding: 0;
      }
      @media ${screen.large} {
        position: absolute;
        right: 0;
        top: 0;
        margin-right: 40px;
        margin-top: 30px;
      }

      .heading-text {
        color: #fff;
        font-size: 1.4rem;
        margin-bottom: 15px;
        text-align: center;
        @media ${screen.large} {
          text-align: left;
          font-size: 1.1rem;
        }
      }
      form {
        /* max-width: 330px; */
        margin: 0 auto;
      }

      input {
        color: #3a3a3a;
        width: 300px;
        border: 0;
        outline: none;
        border-radius: 5px;
        background: ${COLOR_LIGHT_GRAY};
        box-sizing: border-box;
        display: block;
        margin: 0 auto;
        font-size: 1.1rem;
        padding: 8px 11px;
        @media ${screen.large} {
          width: 300px;
          display: inline-block;
        }
        @media ${screen.largeXL} {
          width: 400px;
          padding: 11px;
        }
      }

      button {
        background: #b1b1b1;
        border: 2px #b1b1b1 solid;
        display: block;
        padding: 0.75rem 2rem 0.54rem 2rem;
        padding: 0.5rem 1.85rem 0.3rem 1.85rem;
        width: 300px;
        margin: 10px auto 0 auto;
        @media ${screen.large} {
          display: inline-block;
          margin: 0 0 0 10px;
          width: auto;
        }
        @media ${screen.largeXL} {
          padding: 0.6rem 2rem 0.4rem 2rem;
        }

        &:hover {
          background: ${COLOR_DARK_GREEN};
          border: 2px ${COLOR_DARK_GREEN} solid;
        }
      }

      .links-container-on-mobile {
        margin: 10px auto 0 auto;
        display: flex;
        justify-content: center;
        @media ${screen.large} {
          display: none;
        }
        a {
          outline: none;

          img {
            width: 30px;
            display: inline-block;
            margin: 10px 6px 0 6px;
          }
        }
      }

      .text-feedback {
        text-align: center;
        margin-top: 5px;
        font-size: 1rem;
        text-shadow: 1px 1px 1px rgba(000, 000, 000, 0.7);
        @media ${screen.large} {
          text-align: right;
          font-size: 1.1rem;
          margin-top: 10px;
        }
      }

      .button-loader-container {
        display: block;
        position: relative;
        @media ${screen.large} {
          display: inline-block;
        }
        img {
          position: absolute;
          width: 78px;
          z-index: 2;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &__flora-program {
      background-color: ${COLOR_DARK_GREEN};
      padding: 15px;
      position: absolute;
      bottom: 0;
      overflow: hidden;
      max-width: 1292px;
      display: none;
      width: 100%;
      box-sizing: border-box;
      @media ${screen.largeXL} {
        display: flex;
      }

      p {
        font-size: 0.78rem;
        line-height: 1.25;
        line-height: 1.43;
        max-width: 525px;
        color: #fff;
        margin-right: 14px;
        @media ${screen.largeXXL} {
          max-width: 750px;
        }
      }
      a {
        background: #fff;
        color: ${COLOR_DARK_GREEN};
        align-self: center;
        border: 2px #fff solid;

        &:hover {
          background: ${COLOR_DARK_GRAY};
          border: 2px ${COLOR_DARK_GRAY} solid;
          color: #fff;
        }
      }
    }
  }

  .footer-bottom {
    background-color: ${COLOR_DARK_GRAY};

    &__subcontainer {
      max-width: ${MAX_WIDTH};
      margin: 0 auto;
      padding: 10px 18px;
    }

    p {
      font-size: 0.8rem;
      color: #979797;
      line-height: 1.5;
      text-align: center;
      @media ${screen.large} {
        font-size: 0.72rem;
        text-align: left;
      }
      a {
        color: ${COLOR_DARK_GREEN};
      }
      span {
        display: block;
        @media ${screen.large} {
          display: inline;
        }
      }
    }
  }
`;

const Footer = (props) => {
  const [email, setEmail] = useState('');
  const [emailExist, setEmailExist] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!emailRegX.test(email)) {
      setEmailError(true);
      setEmail('');
    } else {
      setEmail('');
      setLoader(true);
      addToMailchimp(email).then((data) => {
        const checkEmail = data.msg.toLowerCase().split(' ');
        if (
          checkEmail.includes(email.toLowerCase()) ||
          checkEmail.includes(`"${email.toLowerCase()}"`)
        ) {
          // already in the list
          setEmailExist(true);
          setLoader(false);
        } else {
          //success added
          setEmailSuccess(true);
          setLoader(false);
        }
      });
    }
  };

  const handleFormFocus = () => {
    setEmailError(false);
    setEmailExist(false);
    setEmailSuccess(false);
  };

  return (
    <Wrapper>
      <div className="footer-container">
        <div className="footer-left">
          <h5>Explore</h5>
          <ul>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li className="recycling-products-link-list">
              <h6>Recycling Products</h6>
              <Link to="/recycling-products/outdoors">Outdoors</Link>
              <Link to="/recycling-products/home">Home</Link>
              <Link to="/recycling-products/commercial">Commercial</Link>
              <Link to="/recycling-products/hospital">Hospital</Link>
              <Link to="/recycling-products/campus">Campus</Link>
              <Link to="/recycling-products/school">School</Link>
            </li>
            <Link to="/compost-a-pak">
              Compost-a-Pak<span>&reg;</span>
            </Link>
            <Link to="/services">Services</Link>
            <Link to="/blog">Blog</Link>
            <Link to="/contact">Contact</Link>
          </ul>
        </div>
        <div className="footer-right">
          <div className="footer-right__social-media">
            <h5 className="heading-text">Follow</h5>
            <div className="links-container">
              <a
                href="https://www.facebook.com/sourceseparationsystems"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Source Separation Systems Facebook" />
              </a>
              <a
                href="https://twitter.com/SourceSepSystem"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="Source Separation Systems Twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/source-separation-systems/about/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="Source Separation Systems LinkedIn" />
              </a>
            </div>
            <div className="member-logos">
              <img
                className="member-logos__government"
                src={member1}
                alt="Proud Member Local Government Procurement"
              />
              <img
                className="member-logos__wmaa"
                src={member2}
                alt="Proud Member WMAA"
              />
            </div>
          </div>
          <div className="footer-right__newsletter">
            <h5 className="heading-text" onClick={() => setLoader(!loader)}>
              Subscribe to our newsletter
            </h5>
            <form onSubmit={handleSubmit} onFocus={handleFormFocus}>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                placeholder="your email"
              />
              <div className="button-loader-container">
                <Button style={{ opacity: loader ? 0 : 1 }}>Sign up</Button>
                {loader ? <img src={loaderIcon} alt="loading.." /> : null}
              </div>

              {emailExist ? (
                <p className="text-feedback" style={{ color: '#ff0000' }}>
                  Looks like you're already subscribed.
                </p>
              ) : emailError ? (
                <p className="text-feedback" style={{ color: '#ff0000' }}>
                  Please provide a valid email address.
                </p>
              ) : emailSuccess ? (
                <p className="text-feedback" style={{ color: '#9acc7c' }}>
                  Your subscription has been confirmed.
                </p>
              ) : null}
            </form>
            <div className="links-container-on-mobile">
              <a
                href="https://www.facebook.com/sourceseparationsystems"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Source Separation Systems Facebook" />
              </a>
              <a
                href="https://twitter.com/SourceSepSystem"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="Source Separation Systems Twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/source-separation-systems/about/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="Source Separation Systems LinkedIn" />
              </a>
            </div>
          </div>
          <div className="footer-right__flora-program">
            <p>
              We invite you to get involved in our{' '}
              <strong>Native Flora Program</strong>. To date, in addition to our
              support for research into our precious Australian Flora, our
              passionate customers have committed to plant and nurture over
              75,000 rare native seeds.
            </p>
            <StyledLink to="/native-flora">Find out more</StyledLink>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom__subcontainer">
          <p>
            &copy; Copyright {new Date().getFullYear()} Source Separation
            Systems Pty Ltd.{' '}
            <span>
              All Rights Reserved. Built by{' '}
              <a
                className="handsome"
                style={{ whiteSpace: 'nowrap' }}
                href="https://www.hellohandsome.com.au/"
                target="_blank noopener"
              >
                Handsome Creative
              </a>
            </span>
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;
