import React, { useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import ScrollLock from 'react-scrolllock';
import { Waypoint } from 'react-waypoint';
import MobileNav from './MobileNav';
import Branding from './Branding';
import Menus from './Menus';
import Counter from './Counter';
import { MAX_WIDTH_SITE, screen } from '../common/variables';
import stickyHeaderLogo from '../../images/sticky-header-logo.png';
import videoLandFill from '../../videos/landfill-video.mp4';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { VideoModalContainer } from './Counter';

const Wrapper = styled.header`
  max-width: ${MAX_WIDTH_SITE};
  width: 100%;
  margin: 0 auto;
  display: none;
  @media ${screen.largeXL} {
    display: block;
  }

  .container-1 {
    position: fixed;
    top: 0;
    z-index: 9;
    background: white;
    max-width: ${MAX_WIDTH_SITE};
    width: 100%;
  }

  .container-2 {
    width: 100%;
    height: 50px;
    background: white;
    display: flex;
    justify-content: space-between;
    max-width: 1170;
    margin: 0 auto;

    img {
      z-index: 9999;
      height: 30px;
      padding: 10px;
    }
  }
`;

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(dataQuery);

  const searchable = [
    ...data.cpProducts.edges.map((e) => e.node),
    ...data.products.edges.map((e) => e.node),
    ...data.blogPosts.edges.map((e) => e.node),
  ];
  const [stickyHeaderOpen, setStickyHeader] = useState(false);
  const [videoIsShowing, setVideoIsShowing] = useState(false);

  return (
    <React.Fragment>
      <MobileNav
        siteTitle={`Source Separation System`}
        searchable={searchable}
      />
      <Wrapper>
        <Waypoint
          topOffset="90px"
          bottomOffset="50px"
          onEnter={() => setStickyHeader(false)}
          onLeave={() => setStickyHeader(true)}
        >
          <div>
            <Branding siteTitle={siteTitle} />
            <Menus searchable={searchable} phone={'1300 739 913'} />
          </div>
        </Waypoint>
        {videoIsShowing ? (
          <VideoModalContainer>
            <div className="subcontainer">
              <IoIosCloseCircleOutline
                onClick={() => setVideoIsShowing(false)}
              />
              <video controls autoPlay>
                <source src={videoLandFill} type="video/mp4" />
              </video>
            </div>
            <ScrollLock />
          </VideoModalContainer>
        ) : null}
        {stickyHeaderOpen && (
          <div className="container-1">
            <div
              className="container-2"
              onClick={() => setVideoIsShowing(!videoIsShowing)}
            >
              <Link to="/">
                <img alt="Source Separation Systems" src={stickyHeaderLogo} />
              </Link>
              <Counter compact />
            </div>
            <Menus searchable={searchable} phone={'1300 739 913'} />
          </div>
        )}
      </Wrapper>
    </React.Fragment>
  );
};

export default Header;

const dataQuery = graphql`
  {
    products: allWpProduct {
      edges {
        node {
          id
          title
          nodeType
          slug
          acfProduct {
            desc
            mainImages {
              productCode
              image {
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    }

    blogPosts: allWpPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          slug
          title
          nodeType
          date
          excerpt
          acfBlog {
            content
            featuredImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
    cpProducts: allWpCompostAPakProduct {
      edges {
        node {
          id
          slug
          title
          nodeType
          acfCompostAPakProduct {
            streamChoices {
              desc
              image {
                id
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 400
                      height: 400
                    )
                  }
                }
              }
            }
            catImage {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 400
                      height: 400
                    )
                  }
                }
              }
            }
            mainImages {
              productCode
              image {
                sourceUrl
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 900)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
